@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap);
* {
  margin: 0 5px;
  font-family: "Quicksand";
  color: #333;
  background-color: #fafafa;
  border-radius: 6px;
  text-align: center;
}
p {
  font-size: 18px;  
}

.about_page {
    width: 100%;
}
.about_page h1 {
    color: #5a1db5;;
}
.about_body {
    padding: 5px;
    text-align: justify;
    max-width: 1000px;
    margin: auto;
}

/* greater than 600 px */
/*Navbar CSS Below... */
.navbar {
    padding: 20px;
    display: flex;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    border-bottom: 1px solid #e1e1e1;
    border-radius: 0px;
  }
  .navbar h1 {
    color: #5a1db5;
    font-size: 32px;
  }
  .navbar .links {
    margin-left: auto;
    font-size: 20px;
  }
  .navbar a {
    margin-left: 10px;
    text-decoration: none;
    padding: 6px;
  }
  .navbar a:hover {
    color: #5a1db5;
  }
  
  
.homepage {
    max-width: 1200px;
    border-radius: 0;
    margin: 0 auto;
    height: 900px;
}
.log-in-tile {
    max-width: 600px;
    margin: 30px auto;
    padding: 5px;
    border: solid 3px #5a1db5;
    background: #eee;
}
.log-in-tile * {
    padding: 10px;
    margin: 5px auto;
    background: none;
}
.log-in-tile a {
    text-decoration: none;
    padding: none;
    margin: none;
}
.log-in-tile p {
    font-size: 20px;
}
.log-in-button {
    background: #5a1db5;
    color: #fafafa;
    border: none;
    font-size: 18px;
}
.log-in-button:hover {
    cursor: pointer;
}
@media (max-height: 900px) {
    .log-in-tile {
        max-width: 350px;
        border-width: 2px;
    }
}
